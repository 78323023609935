<template>
  <div id="app">
    <UserLayout v-if="route.path.startsWith('/user')">
      <routernpm install --save qrcode-view />
    </UserLayout>
    <BasicLayout v-else />
  </div>
</template>

<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import { useRoute } from "vue-router";
import { onMounted } from "vue";

const route = useRoute();

/**
 * 全局初始化函数，有全局单词调用的代码，都可以写这里
 */
const doInit = () => {
  console.log("hello 欢迎使用AI - SIRI");
};

// onMounted 生命周期钩子函数只会在组件第一次被创建并挂载到 DOM 上时执行一次
onMounted(() => {
  doInit();
});
</script>

<style>
#app {
}
</style>
