import { defineStore } from "pinia";
import { ref } from "vue";
import { getLoginUserUsingGet } from "@/api/userController";
import ACCESS_ENUM from "@/access/accessEnum";
import axios from "axios";

// 定义了一个名为"loginUser"的store
export const userLoginUserStore = defineStore("loginUser", () => {
  // 返回一个包含loginUser响应式对象的函数，loginUser是一个API.LoginUserVO类型的ref。
  // API.LoginUserVO是一个接口，表示登录用户的信息。
  const loginUser = ref<API.LoginUserVO>({
    userName: "未登录",
  });

  // 异步函数是一种在执行过程中可以暂停执行，然后等待某个操作完成后再继续执行的函数
  async function fetchLoginUser() {
    axios.defaults.withCredentials = true;
    const res = await getLoginUserUsingGet();
    if (res.data.code === 0 && res.data.data) {
      loginUser.value = res.data.data;
    } else {
      loginUser.value = { userRole: ACCESS_ENUM.NOT_LOGIN };
    }
  }

  function setLoginUser(newLoginUser: API.LoginUserVO) {
    loginUser.value = newLoginUser;
  }

  return { loginUser, setLoginUser, fetchLoginUser };
});
